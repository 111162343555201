(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ajuntaments.ajuntament.controller:AjuntamentCtrl
   *
   * @description
   *
   */
  angular
  .module('neo.home.ajuntaments.ajuntament')
  .controller('AjuntamentCtrl', AjuntamentCtrl);

  function AjuntamentCtrl() {
    var vm = this;
    vm.ctrlName = 'AjuntamentCtrl';
    vm.currentNavItem = 'informacio';
  }
}());
